@charset "UTF-8";
/** 2019/2/18
 * @Author: Xiao
 * @Desc: flex布局
 */
/** 2019/2/18
 * @Author: Xiao
 * @Desc: 多行超出省略号
 */
/* margin语法糖 */
.mt4[data-v-5e8888c8] {
  margin-top: 0.39063vw;
}
.mt8[data-v-5e8888c8] {
  margin-top: 0.78125vw;
}
.mt16[data-v-5e8888c8] {
  margin-top: 1.5625vw;
}
.ml4[data-v-5e8888c8] {
  margin-left: 0.39063vw;
}
.ml8[data-v-5e8888c8] {
  margin-left: 0.78125vw;
}
.ml16[data-v-5e8888c8] {
  margin-left: 1.5625vw;
}
.mr4[data-v-5e8888c8] {
  margin-right: 0.39063vw;
}
.mr8[data-v-5e8888c8] {
  margin-right: 0.78125vw;
}
.mr16[data-v-5e8888c8] {
  margin-right: 1.5625vw;
}
.mb4[data-v-5e8888c8] {
  margin-bottom: 0.39063vw;
}
.mb8[data-v-5e8888c8] {
  margin-bottom: 0.78125vw;
}
.mb16[data-v-5e8888c8] {
  margin-bottom: 1.5625vw;
}
.mx4[data-v-5e8888c8] {
  margin-left: 0.39063vw;
  margin-right: 0.39063vw;
}
.mx8[data-v-5e8888c8] {
  margin-left: 0.78125vw;
  margin-right: 0.78125vw;
}
.mx16[data-v-5e8888c8] {
  margin-left: 1.5625vw;
  margin-right: 1.5625vw;
}
.pl4[data-v-5e8888c8] {
  padding-left: 0.39063vw;
}
.pl8[data-v-5e8888c8] {
  padding-left: 0.78125vw;
}
.pl16[data-v-5e8888c8] {
  padding-left: 1.5625vw;
}
.pr4[data-v-5e8888c8] {
  padding-right: 0.39063vw;
}
.pr8[data-v-5e8888c8] {
  padding-right: 0.78125vw;
}
.pr16[data-v-5e8888c8] {
  padding-right: 1.5625vw;
}
.pt4[data-v-5e8888c8] {
  padding-top: 0.39063vw;
}
.pt8[data-v-5e8888c8] {
  padding-top: 0.78125vw;
}
.pt16[data-v-5e8888c8] {
  padding-top: 1.5625vw;
}
.pb4[data-v-5e8888c8] {
  padding-bottom: 0.39063vw;
}
.pb8[data-v-5e8888c8] {
  padding-bottom: 0.78125vw;
}
.pb16[data-v-5e8888c8] {
  padding-bottom: 1.5625vw;
}
.px4[data-v-5e8888c8] {
  padding-left: 0.39063vw;
  padding-right: 0.39063vw;
}
.px8[data-v-5e8888c8] {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}
.px16[data-v-5e8888c8] {
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
}
.layout-header__wrapper[data-v-5e8888c8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.layout-header__wrapper ul[data-v-5e8888c8] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.layout-header__wrapper ul li[data-v-5e8888c8] {
      -webkit-box-flex: 0 !important;
      -webkit-flex: 0 0 6.83594vw !important;
          -ms-flex: 0 0 6.83594vw !important;
              flex: 0 0 6.83594vw !important;
      height: 4.10156vw;
      border: 1px solid #ddd;
      border-top: none;
      margin-right: 1.46484vw;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
}
.layout-header__wrapper ul li.active[data-v-5e8888c8] {
        background-color: #96b327;
        color: #fff;
}
.layout-header__wrapper ul li span[data-v-5e8888c8] {
        font-size: 1.17188vw;
}
.layout-header__wrapper ul.layout-header__left[data-v-5e8888c8] {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
.layout-header__wrapper ul.layout-header__right[data-v-5e8888c8] {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 14.64844vw;
          -ms-flex: 0 0 14.64844vw;
              flex: 0 0 14.64844vw;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end;
}
.layout-header__wrapper ul.layout-header__right li[data-v-5e8888c8] {
        margin-right: 0;
}
