
#app {
  overflow: scroll;
}
/* ElementUI的Input输入框NumBer类型删掉尾部的上下按钮 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.element::-webkit-scrollbar {
  display: none;
}
.element {
  -ms-overflow-style: none;
}
.element {
  overflow: -moz-scrollbars-none;
}
