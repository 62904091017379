@charset "UTF-8";
/** 2019/2/18
 * @Author: Xiao
 * @Desc: flex布局
 */
/** 2019/2/18
 * @Author: Xiao
 * @Desc: 多行超出省略号
 */
/* margin语法糖 */
.mt4[data-v-586ccef3] {
  margin-top: 0.39063vw;
}
.mt8[data-v-586ccef3] {
  margin-top: 0.78125vw;
}
.mt16[data-v-586ccef3] {
  margin-top: 1.5625vw;
}
.ml4[data-v-586ccef3] {
  margin-left: 0.39063vw;
}
.ml8[data-v-586ccef3] {
  margin-left: 0.78125vw;
}
.ml16[data-v-586ccef3] {
  margin-left: 1.5625vw;
}
.mr4[data-v-586ccef3] {
  margin-right: 0.39063vw;
}
.mr8[data-v-586ccef3] {
  margin-right: 0.78125vw;
}
.mr16[data-v-586ccef3] {
  margin-right: 1.5625vw;
}
.mb4[data-v-586ccef3] {
  margin-bottom: 0.39063vw;
}
.mb8[data-v-586ccef3] {
  margin-bottom: 0.78125vw;
}
.mb16[data-v-586ccef3] {
  margin-bottom: 1.5625vw;
}
.mx4[data-v-586ccef3] {
  margin-left: 0.39063vw;
  margin-right: 0.39063vw;
}
.mx8[data-v-586ccef3] {
  margin-left: 0.78125vw;
  margin-right: 0.78125vw;
}
.mx16[data-v-586ccef3] {
  margin-left: 1.5625vw;
  margin-right: 1.5625vw;
}
.pl4[data-v-586ccef3] {
  padding-left: 0.39063vw;
}
.pl8[data-v-586ccef3] {
  padding-left: 0.78125vw;
}
.pl16[data-v-586ccef3] {
  padding-left: 1.5625vw;
}
.pr4[data-v-586ccef3] {
  padding-right: 0.39063vw;
}
.pr8[data-v-586ccef3] {
  padding-right: 0.78125vw;
}
.pr16[data-v-586ccef3] {
  padding-right: 1.5625vw;
}
.pt4[data-v-586ccef3] {
  padding-top: 0.39063vw;
}
.pt8[data-v-586ccef3] {
  padding-top: 0.78125vw;
}
.pt16[data-v-586ccef3] {
  padding-top: 1.5625vw;
}
.pb4[data-v-586ccef3] {
  padding-bottom: 0.39063vw;
}
.pb8[data-v-586ccef3] {
  padding-bottom: 0.78125vw;
}
.pb16[data-v-586ccef3] {
  padding-bottom: 1.5625vw;
}
.px4[data-v-586ccef3] {
  padding-left: 0.39063vw;
  padding-right: 0.39063vw;
}
.px8[data-v-586ccef3] {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}
.px16[data-v-586ccef3] {
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
}
.layout-aside__wrapper[data-v-586ccef3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow: hidden;
  overflow-y: auto;
}
.layout-aside__wrapper li[data-v-586ccef3] {
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 6.05469vw !important;
        -ms-flex: 0 0 6.05469vw !important;
            flex: 0 0 6.05469vw !important;
    width: 6.83594vw;
    border: 1px solid #ddd;
    border-left: none;
    margin-bottom: 0.48828vw;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.17188vw;
}
.layout-aside__wrapper li.active[data-v-586ccef3] {
      background-color: #96b327;
      color: #fff;
}
.layout-aside__wrapper li span[data-v-586ccef3] {
      margin-top: 1.46484vw;
}
.layout-aside__wrapper[data-v-586ccef3]::-webkit-scrollbar {
  display: none;
}
