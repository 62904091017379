@charset "UTF-8";
/** 2019/2/18
 * @Author: Xiao
 * @Desc: flex布局
 */
/** 2019/2/18
 * @Author: Xiao
 * @Desc: 多行超出省略号
 */
/* margin语法糖 */
.mt4[data-v-038e7047] {
  margin-top: 0.39063vw;
}
.mt8[data-v-038e7047] {
  margin-top: 0.78125vw;
}
.mt16[data-v-038e7047] {
  margin-top: 1.5625vw;
}
.ml4[data-v-038e7047] {
  margin-left: 0.39063vw;
}
.ml8[data-v-038e7047] {
  margin-left: 0.78125vw;
}
.ml16[data-v-038e7047] {
  margin-left: 1.5625vw;
}
.mr4[data-v-038e7047] {
  margin-right: 0.39063vw;
}
.mr8[data-v-038e7047] {
  margin-right: 0.78125vw;
}
.mr16[data-v-038e7047] {
  margin-right: 1.5625vw;
}
.mb4[data-v-038e7047] {
  margin-bottom: 0.39063vw;
}
.mb8[data-v-038e7047] {
  margin-bottom: 0.78125vw;
}
.mb16[data-v-038e7047] {
  margin-bottom: 1.5625vw;
}
.mx4[data-v-038e7047] {
  margin-left: 0.39063vw;
  margin-right: 0.39063vw;
}
.mx8[data-v-038e7047] {
  margin-left: 0.78125vw;
  margin-right: 0.78125vw;
}
.mx16[data-v-038e7047] {
  margin-left: 1.5625vw;
  margin-right: 1.5625vw;
}
.pl4[data-v-038e7047] {
  padding-left: 0.39063vw;
}
.pl8[data-v-038e7047] {
  padding-left: 0.78125vw;
}
.pl16[data-v-038e7047] {
  padding-left: 1.5625vw;
}
.pr4[data-v-038e7047] {
  padding-right: 0.39063vw;
}
.pr8[data-v-038e7047] {
  padding-right: 0.78125vw;
}
.pr16[data-v-038e7047] {
  padding-right: 1.5625vw;
}
.pt4[data-v-038e7047] {
  padding-top: 0.39063vw;
}
.pt8[data-v-038e7047] {
  padding-top: 0.78125vw;
}
.pt16[data-v-038e7047] {
  padding-top: 1.5625vw;
}
.pb4[data-v-038e7047] {
  padding-bottom: 0.39063vw;
}
.pb8[data-v-038e7047] {
  padding-bottom: 0.78125vw;
}
.pb16[data-v-038e7047] {
  padding-bottom: 1.5625vw;
}
.px4[data-v-038e7047] {
  padding-left: 0.39063vw;
  padding-right: 0.39063vw;
}
.px8[data-v-038e7047] {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
}
.px16[data-v-038e7047] {
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
}
.layout .layout-header[data-v-038e7047] {
  width: 100%;
  height: 5.85938vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 0.48828vw solid #ddd;
}
.layout .layout-wrapper[data-v-038e7047] {
  height: calc(100vh - 5.85938vw);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.layout .layout-wrapper .layout-aside[data-v-038e7047] {
    width: 7.8125vw;
    height: 100%;
    border-right: 0.48828vw solid #ddd;
}
.layout .layout-wrapper .layout-main[data-v-038e7047] {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
    width: 80vw;
}
