@charset "UTF-8";
/** 2019/2/18
 * @Author: Xiao
 * @Desc: flex布局
 */
/** 2019/2/18
 * @Author: Xiao
 * @Desc: 多行超出省略号
 */
/* margin语法糖 */
.mt4 {
  margin-top: 0.39063vw; }

.mt8 {
  margin-top: 0.78125vw; }

.mt16 {
  margin-top: 1.5625vw; }

.ml4 {
  margin-left: 0.39063vw; }

.ml8 {
  margin-left: 0.78125vw; }

.ml16 {
  margin-left: 1.5625vw; }

.mr4 {
  margin-right: 0.39063vw; }

.mr8 {
  margin-right: 0.78125vw; }

.mr16 {
  margin-right: 1.5625vw; }

.mb4 {
  margin-bottom: 0.39063vw; }

.mb8 {
  margin-bottom: 0.78125vw; }

.mb16 {
  margin-bottom: 1.5625vw; }

.mx4 {
  margin-left: 0.39063vw;
  margin-right: 0.39063vw; }

.mx8 {
  margin-left: 0.78125vw;
  margin-right: 0.78125vw; }

.mx16 {
  margin-left: 1.5625vw;
  margin-right: 1.5625vw; }

.pl4 {
  padding-left: 0.39063vw; }

.pl8 {
  padding-left: 0.78125vw; }

.pl16 {
  padding-left: 1.5625vw; }

.pr4 {
  padding-right: 0.39063vw; }

.pr8 {
  padding-right: 0.78125vw; }

.pr16 {
  padding-right: 1.5625vw; }

.pt4 {
  padding-top: 0.39063vw; }

.pt8 {
  padding-top: 0.78125vw; }

.pt16 {
  padding-top: 1.5625vw; }

.pb4 {
  padding-bottom: 0.39063vw; }

.pb8 {
  padding-bottom: 0.78125vw; }

.pb16 {
  padding-bottom: 1.5625vw; }

.px4 {
  padding-left: 0.39063vw;
  padding-right: 0.39063vw; }

.px8 {
  padding-left: 0.78125vw;
  padding-right: 0.78125vw; }

.px16 {
  padding-left: 1.5625vw;
  padding-right: 1.5625vw; }

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  height: 100%; }

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
  overflow: hidden; }

h6 {
  margin: 0; }

input {
  outline: none;
  border: none;
  font-size: 1.36719vw;
  padding-left: 1.5625vw;
  color: #2F2F2F; }

button {
  outline: none;
  border: none; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none; }

/*fade-transform*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-2.92969vw);
          transform: translateX(-2.92969vw); }

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(2.92969vw);
          transform: translateX(2.92969vw); }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.el-input__inner:focus {
  border: 1px solid #96b327; }

.el-button.el-button--primary {
  background-color: #96b327;
  border-color: #96b327; }

.el-button.el-button--text {
  color: #96b327; }

.el-dialog .el-dialog__body {
  padding: 1.46484vw; }

.el-dialog .el-dialog__header {
  padding: 0;
  height: 3.90625vw;
  background-color: #ddd;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0.97656vw; }
  .el-dialog .el-dialog__header span {
    font-size: 1.17188vw; }
  .el-dialog .el-dialog__header .el-dialog__headerbtn {
    top: 0;
    right: 0; }
    .el-dialog .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
      width: 3.90625vw;
      height: 3.90625vw;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; }

.happy-scroll-container {
  width: 100% !important;
  height: 100% !important; }
  .happy-scroll-container .happy-scroll-content {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
    height: 100%;
    border-right: 0 solid transparent !important;
    border-bottom: 0 solid transparent !important; }

.el-table.table-wrapper .el-table__row td {
  padding: 0; }

.el-table.table-wrapper .cell {
  word-break: break-word !important; }

.el-switch.is-checked .el-switch__core {
  border-color: #96b327;
  background-color: #96b327; }

.el-radio__input.is-checked .el-radio__inner {
  border-color: #96b327;
  background: #96b327; }

.el-radio__input.is-checked + .el-radio__label {
  color: #96b327 !important; }

.el-form-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .el-form-item .el-form-item__content {
    width: 100%; }
    .el-form-item .el-form-item__content .el-select {
      width: 100%; }

.el-upload-list__item-actions {
  opacity: 1 !important; }
  .el-upload-list__item-actions span {
    display: inline-block !important; }

.el-image-viewer__btn {
  color: #fff !important; }
